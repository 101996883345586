import React from 'react';
import { Link } from "react-router-dom";


const CustomerCard = (props) => {

    return (

        <div className="col-md-3 col-xs-6">
            <div className="card">
                <div className="card-block">

                    <img className="cardImage" src={props.ApiURL + props.customerLogo} alt="Customer Logo" />



                    <h4 className="card-title mt-4"><center>{props.customerName}</center></h4>
                    <p className="card-text"></p>
                    <div className="card-footer text-center">
                        <Link to={`/customers/${props.customerId}/0`}>
                            <button type="button" className="btn btn-primary"> View</button>
                        </Link>
                        <Link to={`/customer/Edit/${props.customerId}/0`}>
                            <button type="button" className="btn btn-secondary">Edit</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CustomerCard;