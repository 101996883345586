import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from "react-router-dom";
import MaterialTable, { MTableToolbar, MTableBodyRow } from 'material-table'
import axios from 'axios';

const activeList = [{ id: 0, active: "Inactive" }, { id: 1, active: "Active" }]

const tableRef = React.createRef();

const DrageState = {
    row: -1,
    dropIndex: -1 // drag target
};

class ViewCustomer extends Component {


    state = {

        users: [],
        customerName: '',
        usersName: '',
        productList: [],
        isOpenLogo: false,
        popupURL: "",
        IMGdimensions: "",
        popupID: 0,
        isOpen: false,
        productAssignTable: [],
        userExists: true,
        user_id: "0",
        isFlushed: false,
        CurrentStock: 0,
        NewStock: 0,
        popupID: 0,
        isOpen2: false,
        name: '',
        price: '',
        leadTime: '',
        desc: '',
        file: '',
        Enid: '',
        isOpen3: false,
        active: "",
        isOpenAssignProducts: false,
        AssignSelectedRows: [],
        TableData: [],
        rearrange: false


    }

    componentDidMount() {

        fetch(`${this.props.ApiURL}/api/customers/${this.props.match.params.customer_id}/users`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.ok) {
                    return res;
                } else {
                    throw Error(`Request rejected with status ${res.status}`);
                }
            })
            .then(res => {
                return res.json()
            })
            .then(body => {
                this.setState({
                    users: body.Customer
                })
            })
            .catch(console.error)


        this.fetchProducts()

    }

    fetchProducts = () => {

        if (this.state.user_id === "0") {
            fetch(`${this.props.ApiURL}/api/customers/${this.props.match.params.customer_id}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {
                    if (res.ok) {
                        return res;
                    } else {
                        throw Error(`Request rejected with status ${res.status}`);
                    }
                })
                .then(res => {
                    return res.json()
                })
                .then(body => {
                    if (body.Customer.length === 0) {
                        this.setState({
                            customerName: "Customer Not Found",
                            usersName: "User Not Found",
                            userExists: false
                        })
                    } else {
                        this.setState({
                            customerName: body.Customer[0].CustomerName,
                            usersName: "All Labels",
                            userExists: true
                        })
                    }

                })
                .catch(console.log)

            fetch(`${this.props.ApiURL}/api/products/${this.props.match.params.customer_id}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {
                    if (res.ok) {
                        return res;
                    } else {
                        throw Error(`Request rejected with status ${res.status}`);
                    }
                })
                .then(res => {
                    return res.json()
                })
                .then(body => {
                    this.setState({
                        productList: body.Products
                    })
                })
                .catch(console.log)
        } else {

            fetch(`${this.props.ApiURL}/api/users/${this.state.user_id}/${this.props.match.params.customer_id}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {
                    if (res.ok) {
                        return res;
                    } else {
                        throw Error(`Request rejected with status ${res.status}`);
                    }
                })
                .then(res => {
                    return res.json()
                })
                .then(body => {
                    if (body.Customer.length === 0) {
                        this.setState({
                            customerName: "Customer Not Found",
                            usersName: "User Not Found",
                            userExists: false
                        })
                    } else {
                        this.setState({
                            customerName: body.Customer[0].CustomerName,
                            usersName: body.Customer[0].Name,
                            userExists: true
                        })
                    }

                })

            fetch(`${this.props.ApiURL}/api/products/${this.props.match.params.customer_id}/${this.state.user_id}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {
                    if (res.ok) {
                        return res;
                    } else {
                        throw Error(`Request rejected with status ${res.status}`);
                    }
                })
                .then(res => {
                    return res.json()
                })
                .then(body => {
                    this.setState({
                        productList: body.Products
                    })
                })
                .catch(console.log)

            fetch(`${this.props.ApiURL}/api/productsAssign/${this.props.match.params.customer_id}/${this.state.user_id}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {
                    if (res.ok) {
                        return res;
                    } else {
                        throw Error(`Request rejected with status ${res.status}`);
                    }
                })
                .then(res => {
                    return res.json()
                })
                .then(body => {
                    this.setState({
                        productAssignTable: body.Products
                    })
                })
                .catch(console.log)

        }
    }

    handleOpenLogo = ({ url, id, prodID }) => {

        this.setState({
            isOpenLogo: true,
            popupURL: id,
            popupID: prodID
        })

    }

    AllowReorder = () => {

        this.setState({
            rearrange: this.state.rearrange === true ? false : true
        }, this.fetchProducts())

    }

    onFileChange = (e) => {
        this.setState({ file: e.target.files[0] })
    }

    onImgLoad = ({ target: img }) => {
        this.setState({
            IMGdimensions: {
                height: img.offsetHeight,
                width: img.offsetWidth
            }
        });
    }

    updateLogo = (e) => {
        e.preventDefault()
        const formData = new FormData();

        formData.append('Img', this.state.file)
        formData.append('productID', this.state.popupID)

        axios.put(`${this.props.ApiURL}/api/product/logo`, formData)
            .then((body) => {
                this.handleCloseLogo()
                window.location.reload();
            })
            .catch(console.log)
    }

    updateStock = (e) => {
        e.preventDefault()
        fetch(`${this.props.ApiURL}/api/stock`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ProductID: this.state.popupID,
                CustomerID: this.props.match.params.customer_id,
                UserID: this.state.user_id,
                Stock: this.state.NewStock
            })
        })
            .then(res => {
                this.handleClose()
                this.fetchProducts()

            })
            .catch(console.log)
    }

    UnAssignStock = (productID) => {
        fetch(`${this.props.ApiURL}/api/UnAssignstock`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ProductID: productID,
                CustomerID: this.props.match.params.customer_id,
                UserID: this.state.user_id
            })
        })
            .then(res => {
                this.fetchProducts()
            })
            .catch(console.log)
    }

    assignProducts = () => {
        Promise.all(this.state.AssignSelectedRows.map((row) => {
            fetch(`${this.props.ApiURL}/api/stock`, {
                method: "put",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductID: row.id,
                    CustomerID: this.props.match.params.customer_id,
                    UserID: this.state.user_id,
                    Stock: 0
                })
            })
                .catch(error => {
                    console.log(error)
                })
        }))
            .then(() => {
                return tableRef.current.onAllSelected(false)
            })
            .then(() => {
                this.setState({
                    isOpenAssignProducts: false,
                    AssignSelectedRows: []
                }, this.fetchProducts)
            })
    }

    updateProduct = (e) => {
        e.preventDefault()
        fetch(`${this.props.ApiURL}/api/product`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ProductID: this.state.popupID,
                name: this.state.name,
                desc: this.state.desc,
                Enid: this.state.Enid,
                price: this.state.price,
                leadTime: this.state.leadTime,
                active: this.state.active
            })
        })
            .then(res => {
                this.handleClose3()
                this.fetchProducts()

            })
            .catch(console.log)
    }

    addProduct = (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append('Img', this.state.file)
        formData.append('ProductDescription', this.state.desc)
        formData.append('ProductName', this.state.name)
        formData.append('CustomerID', this.props.match.params.customer_id)
        formData.append('EnLabelsID', this.state.Enid)
        formData.append('Price', this.state.price)
        formData.append('LeadTime', this.state.leadTime)

        axios.post(`${this.props.ApiURL}/api/product`, formData)
            .then((body) => {
                this.handleClose2()
                this.fetchProducts()
            })
    }

    handleCloseLogo = () => {

        this.setState({
            isOpenLogo: false
        })

    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleOpen = ({ popupID, mode }) => {
        if (mode === 1) {
            this.setState({
                popupID: popupID[0],
                CurrentStock: popupID[1],
                NewStock: popupID[1],
                PopupTitle: popupID[2],
                PopupDesc: popupID[3],
                isOpen: true
            })
        } else {
            this.setState({
                isOpen3: true,
                popupID: popupID[0],
                name: popupID[2],
                desc: popupID[3],
                Enid: popupID[6],
                price: popupID[5],
                leadTime: popupID[4],
                active: popupID[7]
            })

        }
    }

    handleAssignProducts = () => {
        this.setState({

            isOpenAssignProducts: true
        })
    }

    handleCloseAssign = () => {
        this.setState({

            isOpenAssignProducts: false
        })
    }

    handleClose3 = () => {

        this.setState({

            isOpen3: false
        })

    }

    handleClose = () => {

        this.setState({

            isOpen: false
        })

    }

    handleOpen2 = () => {

        this.setState({
            isOpen2: true
        })

    }

    handleClose2 = () => {

        this.setState({

            isOpen2: false
        })

    }

    handleChangeUser = (userID) => {
        this.setState({
            user_id: userID,
            rearrange: false
        }, this.fetchProducts)

    }

    renderColums = () => {
        if (this.state.user_id === "0") {
            return [
                { title: 'id', field: 'id', width: "2px", hidden: true },
                { title: 'priority', field: 'Priority', width: "2px", hidden: true },
                { title: 'ID', field: 'EnLabelsID', width: "2px" },
                { title: 'Artwork', field: 'ArtworkURL', width: "10%", render: (rowData) => <img onClick={() => this.handleOpenLogo({ imageURL: rowData.ArtworkURL, id: this.props.ApiURL + rowData.ArtworkURL, prodID: rowData.id })} style={{ maxWidth: "160px", paddingRight: "20px" }} src={this.props.ApiURL + rowData.ArtworkURL}></img> },
                { title: 'Label Details', field: 'ProductName', width: "40%", render: (rowData) => <div><h3>{rowData.ProductName}</h3><p>{rowData.ProductDescription}</p></div> },
                { title: 'Price', field: 'Price', width: "20%", render: (rowData) => <div><p>{rowData.Price}</p></div> },
                { title: 'LeadTime', field: 'LeadTime', width: "30%", render: (rowData) => <div><p>{rowData.LeadTime}</p></div> },
                { title: 'Action', field: 'Stock', width: "10%", render: (rowData) => <span><button onClick={() => this.handleOpen({ popupID: [rowData.id, rowData.Stock, rowData.ProductName, rowData.ProductDescription, rowData.LeadTime, rowData.Price, rowData.EnLabelsID, rowData.Active], mode: 2 })} type="button" className="btn btn-primary mb-3" style={{whiteSpace: "nowrap"}}> Edit Product</button></span> }
            ]
        } else {
            return [
                { title: 'id', field: 'id', width: "2px", hidden: true },
                { title: 'ID', field: 'EnLabelsID', width: "2px" },
                { title: 'Artwork', field: 'ArtworkURL', width: "10%", render: (rowData) => <img onClick={() => this.handleOpenLogo({ imageURL: rowData.ArtworkURL, id: this.props.ApiURL + rowData.ArtworkURL, prodID: rowData.id })} style={{ maxWidth: "160px", paddingRight: "20px" }} src={this.props.ApiURL + rowData.ArtworkURL}></img> },
                { title: 'Label Details', field: 'ProductName', width: "40%", render: (rowData) => <div><h3>{rowData.ProductName}</h3><p>{rowData.ProductDescription}</p></div> },
                { title: 'Price', field: 'Price', width: "20%", render: (rowData) => <div><p>{rowData.Price}</p></div> },
                { title: 'LeadTime', field: 'LeadTime', width: "30%", render: (rowData) => <div><p>{rowData.LeadTime}</p></div> },
                { title: 'Stock', field: 'Stock', width: "10%" },
                { title: 'Action', field: 'Stock', width: "10%", render: (rowData) => <span><button onClick={() => this.handleOpen({ popupID: [rowData.id, rowData.Stock, rowData.ProductName, rowData.ProductDescription, rowData.LeadTime, rowData.Price, rowData.EnLabelsID, rowData.Active], mode: 1 })} type="button" className="btn btn-primary mb-3"> Update Stock</button> <button onClick={() => this.UnAssignStock(rowData.id)} type="button" className="btn btn-secondary mb-3"> Unassign Product </button></span> }
            ]
        }
    }

    renderButtons = () =>{
        if (this.state.user_id === "0") {

            if(this.state.rearrange === true){
                return <div><button type="submit" onClick={this.handleOpen2} className="btn btn-secondary mr-1" style={{float:"right"}}>Add Product</button> <button type="submit" onClick={this.AllowReorder} className="btn btn-secondary mr-1" style={{float:"right"}}>Apply Reorder</button></div>
            }
            return <div><button type="submit" onClick={this.handleOpen2} className="btn btn-secondary mr-1" style={{float:"right"}}>Add Product</button> <button type="submit" onClick={this.AllowReorder} className="btn btn-secondary mr-1" style={{float:"right"}}>Reorder Products</button></div>              
        }else{
            return <button type="submit" onClick={this.handleAssignProducts} className="btn btn-secondary mr-1" style={{float:"right"}}>Assign Products</button>
        }
    }


    reOrderRow = (from, to) =>{
        if (from < to) {
        console.log(this.state.productList)
        let start = this.state.productList.slice(0, from),
        between = this.state.productList.slice(from + 1, to + 1),
        end = this.state.productList.slice(to + 1);
        fetch(`${this.props.ApiURL}/api/products/updateDisplayOrder`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                PriorityArray: [...start, ...between, this.state.productList[from], ...end]
            })
        })

        .then(() => {
            this.setState({
                productList: [...start, ...between, this.state.productList[from], ...end]
    
            })
        })
            .catch(console.log)
        }

        if (from > to) {
            console.log(this.state.productList)
            let start = this.state.productList.slice(0, to),
            between = this.state.productList.slice(to, from),
            end = this.state.productList.slice(from + 1);
            fetch(`${this.props.ApiURL}/api/products/updateDisplayOrder`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    PriorityArray: [...start, this.state.productList[from], ...between, ...end]
                })
            })
    
            .then(() => {
                this.setState({
                    productList: [...start, this.state.productList[from], ...between, ...end]
        
                })
            })
                .catch(console.log)
            }
    }

    


    render() {

        return (

            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="topLightBanner">
                            <h1>{this.state.customerName}</h1>
                            <h3>{this.state.usersName}</h3>
                        </div>
                        <div className="topButtonLightBanner row">
                            <div className="col-md-8">
                                <button onClick={() => { this.handleChangeUser("0") }} type="button" className={this.state.user_id == 0 ? 'btn btn-primary mr-2 active' : 'btn btn-primary mr-2'}>All Labels</button>
                                {this.state.users.map((user) => {
                                    return <button key={user.id} onClick={() => { this.handleChangeUser(user.id.toString()) }} type="button" className={this.state.user_id == user.id ? 'btn btn-primary mr-2 active' : 'btn btn-primary mr-2'}>{user.Name}</button>

                                })}
                            </div>
                            <div className="col-md-4">
                                {this.renderButtons()}
                            </div>

                        </div>

                    </div>
                </div>



                <div className="row" style={{ marginLeft: '70px', marginRight: '70px' }}>
                    <div className="col-md-12" >
                        <MaterialTable
                        components={{
                            Row: props => (
                                <MTableBodyRow {...props}
                                    draggable={this.state.rearrange == true ? true : false}
                                    onDragStart={(e) => {
                                        console.log('onDragStart');
                                        DrageState.row = props.data.tableData.id
                                    }}
                                    onDragEnter={e => {
                                        e.preventDefault();
                                        if( props.data.tableData.id !== DrageState.row){
                                            DrageState.dropIndex = props.data.tableData.id ;
                                        }                                                  
                                    }}
                                    onDragEnd={(e) => {
                                        console.log(`onDragEnd`);
                                        if(DrageState.dropIndex !== -1){
                                            this.reOrderRow(DrageState.row, DrageState.dropIndex)
                                        }
                                        DrageState.row = -1;
                                        DrageState.dropIndex = -1;
                                    }}
                                />
                            )
                        }}
                            columns={this.renderColums()}
                            data={this.state.productList}
                            title=""
                            options={{
                                headerStyle: {
                                    backgroundColor: 'white',
                                    borderBottomColor: "Black",
                                    fontSize: "18px",
                                    fontFamily: "Poppins"
                                },
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 50, 100, 500],
                                search: (this.state.rearrange == true ? false : true),
                                actionsColumnIndex: -1,
                                paging: false,
                                searchFieldAlignment: "left",
                                sorting: (this.state.rearrange == true ? false : true)
                            }}
                        />
                    </div>
                </div>

                <Modal isOpen={this.state.isOpen} centered={true}>
                    <ModalHeader >Update Stock</ModalHeader>
                    <ModalBody>
                        <h3>{this.state.PopupTitle}</h3>
                        <p>{this.state.PopupDesc}</p>
                        <p>Current Stock: {this.state.CurrentStock}</p>
                        <div className="row mt-4">
                            <div className="col-4 newStock">New Stock</div>
                            <div className="col-8"><input type="text" onChange={this.handleChange} value={this.state.NewStock} name="NewStock" id="NewStock" className="form-control" placeholder="" /></div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <form onSubmit={this.updateStock}>
                            <button type="submit" className="btn btn-secondary mr-2">Update</button>
                            <button type="button" className="btn btn-secondary" onClick={this.handleClose}>Close</button>
                        </form>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.isOpen3} centered={true}>
                    <ModalHeader >Edit Product</ModalHeader>
                    <ModalBody>
                        <div className="row mt-2">
                            <div className="col-4 newStock">ID</div>
                            <div className="col-8"><input type="text" onChange={this.handleChange} value={this.state.Enid} id="Enid" className="form-control" placeholder="" /></div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 newStock">Name</div>
                            <div className="col-8"><input type="text" onChange={this.handleChange} value={this.state.name} id="name" className="form-control" placeholder="" /></div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 newStock">Price</div>
                            <div className="col-8"><input type="text" onChange={this.handleChange} value={this.state.price} id="price" className="form-control" placeholder="" /></div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 newStock">Lead Time</div>
                            <div className="col-8"><input type="text" onChange={this.handleChange} value={this.state.leadTime} id="leadTime" className="form-control" placeholder="" /></div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 newStock">Description</div>
                            <div className="col-8"><textarea rows="5" type="textarea" onChange={this.handleChange} value={this.state.desc} id="desc" className="form-control" placeholder="" /></div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 newStock">Status</div>
                            <div className="col-8"><select className="form-control" value={this.state.active} onChange={this.handleChangeActive} id="Status">
                                {activeList.map(activeItem => {
                                    return <option key={activeItem.id} value={activeItem.id}>{activeItem.active}</option>
                                })}
                            </select></div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <form onSubmit={this.updateProduct}>
                            <button type="submit" className="btn btn-secondary mr-2">Update</button>
                            <button type="button" className="btn btn-secondary" onClick={this.handleClose3}>Close</button>
                        </form>
                    </ModalFooter>
                </Modal>


                <Modal isOpen={this.state.isOpenLogo} centered={true} className={(this.state.IMGdimensions.width / this.state.IMGdimensions.height) > 1.25 ? 'ImageLong' : 'ImageSquare'}>
                    <ModalHeader >Update Artwork</ModalHeader>
                    <ModalBody>
                        <div className="row mt-2">
                            <div className="col-4 newStock">Artwork </div>
                            <div className="col-8"><input type="file" id="file" onChange={this.onFileChange} className="form-control" /></div>
                        </div>
                        <img style={{ maxWidth: "90%", margin: "40px auto", display: "block" }} onLoad={this.onImgLoad} src={this.state.popupURL}></img>
                    </ModalBody>
                    <ModalFooter>
                        <div className="form-group col-md-12 row">
                            <button type="submit" onClick={this.updateLogo} className="btn btn-secondary">Update Artwork</button>
                            <button type="button" className="btn btn-secondary ml-2" onClick={this.handleCloseLogo}>Close</button>
                        </div>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.isOpenAssignProducts} className="AssignPopup" centered={true}>
                    <ModalHeader >Assign Products</ModalHeader>
                    <ModalBody>
                        <MaterialTable
                            columns={[
                                { title: 'id', field: 'id', width: "2px", hidden: true },
                                { title: 'ID', field: 'EnLabelsID', width: "2px", defaultSort: "asc", },
                                { title: 'Label Details', field: 'ProductName', width: "40%" },
                            ]}
                            data={this.state.productAssignTable}
                            title=""
                            tableRef={tableRef}
                            options={{
                                headerStyle: {
                                    backgroundColor: 'white',
                                    borderBottomColor: "Black",
                                    fontSize: "18px",
                                    fontFamily: "Poppins"
                                },
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 50],
                                search: true,
                                actionsColumnIndex: -1,
                                paging: true,
                                searchFieldAlignment: "left",
                                selection: true
                            }}
                            onSelectionChange={(rows) => {
                                this.setState({
                                    AssignSelectedRows: rows
                                })
                            }}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <div className="form-group col-md-12 row">
                            <button type="submit" onClick={this.assignProducts} className="btn btn-secondary">Assign Selected</button>
                            <button type="button" className="btn btn-secondary ml-2" onClick={this.handleCloseAssign}>Close</button>
                        </div>
                    </ModalFooter>
                </Modal>


                <Modal isOpen={this.state.isOpen2} centered={true} >
                    <ModalHeader >Add New Product</ModalHeader>
                    <ModalBody>
                        <div className="row mt-2">
                            <div className="col-4 newStock">ID</div>
                            <div className="col-8"><input type="text" onChange={this.handleChange} value={this.state.Enid} id="Enid" className="form-control" placeholder="" /></div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 newStock">Name</div>
                            <div className="col-8"><input type="text" onChange={this.handleChange} value={this.state.name} id="name" className="form-control" placeholder="" /></div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 newStock">Price</div>
                            <div className="col-8"><input type="text" onChange={this.handleChange} value={this.state.price} id="price" className="form-control" placeholder="" /></div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 newStock">Lead Time</div>
                            <div className="col-8"><input type="text" onChange={this.handleChange} value={this.state.leadTime} id="leadTime" className="form-control" placeholder="" /></div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 newStock">Description</div>
                            <div className="col-8"><textarea rows="5" type="textarea" onChange={this.handleChange} value={this.state.desc} id="desc" className="form-control" placeholder="" /></div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 newStock">Artwork</div>
                            <div className="col-8"><input type="file" id="file" onChange={this.onFileChange} className="form-control" /></div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <form onSubmit={this.addProduct}>
                            <button type="submit" className="btn btn-secondary mr-2">Add</button>
                            <button type="button" className="btn btn-secondary" onClick={this.handleClose2}>Close</button>
                        </form>
                    </ModalFooter>
                </Modal>


            </div>
        );
    }
}

export default withRouter(ViewCustomer);
