import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ReactCollapsingTable from 'react-collapsing-table'
import ProccessOrder from '../Components/TableFunctions/ProccessOrder'
import ProccessStockUpdate from '../Components/TableFunctions/ProccessStockUpdate'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


class Dashboard extends Component {


    columns = [
        { accessor: 'Product', label: 'Product', priorityLevel: 3, position: 2, minWidth: 100, },
        { accessor: 'Customer', label: 'Customer', priorityLevel: 9, position: 1, minWidth: 100, },
        { accessor: 'UserName', label: 'Site', priorityLevel: 1, position: 3, minWidth: 310, },
        { accessor: 'Qty', label: 'Qty Requested', priorityLevel: 5, position: 4, minWidth: 100, },
        { accessor: 'Date', label: 'Date', priorityLevel: 6, position: 5, minWidth: 100, },
        { accessor: 'Actions', label: 'Actions', priorityLevel: 2, position: 6, minWidth: 110, CustomComponent: ProccessOrder }
    ]

    columns2 = [
        { accessor: 'Product', label: 'Product', priorityLevel: 3, position: 2, minWidth: 100, },
        { accessor: 'Customer', label: 'Customer', priorityLevel: 9, position: 1, minWidth: 100, },
        { accessor: 'UserName', label: 'Site', priorityLevel: 1, position: 3, minWidth: 310, },
        { accessor: 'CurrentStock', label: 'Current Stock', priorityLevel: 5, position: 4, minWidth: 100, },
        { accessor: 'NewStock', label: 'New Stock', priorityLevel: 5, position: 4, minWidth: 100, },
        { accessor: 'Date', label: 'Date', priorityLevel: 6, position: 5, minWidth: 100, }
    ]

    state = {
        CustomerList: [],
        orders: [],
        stockUpdates: [],
        CurrentStock: 0,
        NewStock: 0,
        PopupTitle: '',
        isOpen: false, 
        ProductID: 0,
        UserID: 0,
        CustomerID: 0,
        isOpenOrder: false, 
        CustomerName: '',
        SiteName: '',
        Notes: ''
    }

    componentDidMount() {
        fetch(`${this.props.ApiURL}/api/orders`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.ok) {
                    return res;
                } else {
                    throw Error(`Request rejected with status ${res.status}`);
                }
            })
            .then(res => {
                return res.json()
            })
            .then(body => {

                return body.Orders.map(order => {

                    return this.setState({ orders: [...this.state.orders, { id: order.id, Product: `<p>${order.ProductName}</p>`, Customer: `<p>${order.CustomerName}</p>`, UserName: `<p>${order.Name}</p>`, Qty: `<p>${order.QtyRequested}</p>`, Date: `<p>${this.ConvertDate(order.DateRequested)}</p>`, Actions: [order.id, order.ProductName, , order.QtyRequested, order.ProductID, order.UserID, order.CustomerID, order.CustomerName, order.Name, order.Notes] }] })

                })
            })
            .catch(console.error)

        fetch(`${this.props.ApiURL}/api/stockupdates`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.ok) {
                    return res;
                } else {
                    throw Error(`Request rejected with status ${res.status}`);
                }
            })
            .then(res => {
                return res.json()
            })
            .then(body => {

                return body.StockUpdates.map(update => {

                    return this.setState({ stockUpdates: [...this.state.stockUpdates, { id: update.id, Product: `<p>${update.ProductName}</p>`, Customer: `<p>${update.CustomerName}</p>`, UserName: `<p>${update.Name}</p>`, CurrentStock: `<p>${update.CurrentStock}</p>`, NewStock: `<p>${update.NewStock}</p>`, Date: `<p>${this.ConvertDate(update.DateRequested)}</p>`, Actions: [update.id, update.ProductName, update.CurrentStock, update.NewStock, update.ProductID, update.UserID, update.CustomerID, update.CustomerName] }] })

                })
            })
            .catch(console.error)

    }

    ConvertDate = (dateInput) => {

        return dateInput.slice(8, 10) + "-" + dateInput.slice(5, 7) + "-" + dateInput.slice(0, 4)

    }

    handleOpenStock = ({ popupID }) => {

        this.setState({
            popupID: popupID[0],
            CurrentStock: popupID[2],
            NewStock: popupID[3],
            PopupTitle: popupID[1],
            ProductID: popupID[4],
            UserID: popupID[5],
            CustomerID: popupID[6],
            CustomerName:popupID[7],
            isOpen: true
        })

    }

    handleOpenOrders = ({ popupID }) => {

        this.setState({
            popupID: popupID[0],
            CurrentStock: popupID[2],
            NewStock: popupID[3],
            PopupTitle: popupID[1],
            ProductID: popupID[4],
            UserID: popupID[5],
            CustomerID: popupID[6],
            isOpenOrder: true,
            CustomerName:popupID[7],
            SiteName: popupID[8],
            Notes: popupID[9]
        })

    }

    handleCloseUpdate = () => {

        this.setState({

            isOpen: false
        })

    }

    handleCloseOrder = () => {

        this.setState({

            isOpenOrder: false
        })

    }

    approveStockUdate = (e) => {
        fetch(`${this.props.ApiURL}/api/stockupdate/approve`, {
            method: "PUT",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ProductID: this.state.ProductID,
                CustomerID: this.state.CustomerID,
                UserID: this.state.UserID,
                Stock: this.state.NewStock,
                Orderid: this.state.popupID

            })
          })
            .then(res => {
                this.setState({ isOpenUpdate: false })
            })
    }

    approveOrderUdate = (e) => {
        fetch(`${this.props.ApiURL}/api/order/approve`, {
            method: "PUT",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({

                Orderid: this.state.popupID

            })
          })
            .then(res => {
                this.setState({ isOpenOrder: false })
            })
    }
    
    
    render() {
        const callbacks = { Actions: this.handleOpenStock }
        const callbacks2 = { Actions: this.handleOpenOrders }

        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="topLightBanner">
                            <h1>Dashboard</h1>

                        </div>
                        <div className="topButtonLightBanner">
                            <Link to={'/customers'}>
                                <button type="button" className="btn btn-primary mr-2">View Customers</button>
                            </Link>

                        </div>
                        <div className="row ml-5 mr-5 mt-5">
                            <h2 className="mb-5" >New Approved Orders</h2>
                            <div className="col-md-12" >
                                <ReactCollapsingTable columns={this.columns}
                                    rows={this.state.orders}
                                    rowSize={20}
                                    showSearch={false}
                                    callbacks={callbacks2}
                                    showPagination={true} />
                            </div>

                        </div>

                        <div className="row ml-5 mr-5 mt-5">
                            <h2 className="mb-5" >Stock Updates Log</h2>
                            <div className="col-md-12" >
                                <ReactCollapsingTable columns={this.columns2}
                                    rows={this.state.stockUpdates}
                                    rowSize={20}
                                    showSearch={false}
                                    callbacks={callbacks}
                                    showPagination={true} />
                            </div>

                        </div>

                        <Modal isOpen={this.state.isOpen} centered={true}>
                            <ModalHeader >Stock Update Request</ModalHeader>
                            <ModalBody>
                                <h3>{this.state.PopupTitle}</h3>
                                <p>Customer Name: {this.state.CustomerName}</p>
                                <p>Current Stock: {this.state.CurrentStock}</p>
                                <p>New Stock: {this.state.NewStock}</p>
                                
                            </ModalBody>
                            <ModalFooter>
                                <form onSubmit={this.approveStockUdate}>
                                    <button type="submit" className="btn btn-primary mr-1">Approve</button>
                                    <button type="submit" className="btn btn-primary mr-1">Decline</button>
                                    <button type="button" className="btn btn-secondary" onClick={this.handleCloseUpdate}>Close</button>
                                </form>
                            </ModalFooter>
                        </Modal>

                        <Modal isOpen={this.state.isOpenOrder} centered={true}>
                            <ModalHeader >Order Request</ModalHeader>
                            <ModalBody>
                                <h3>{this.state.PopupTitle}</h3>
                                <p>Customer Name: {this.state.CustomerName}</p>
                                <p>Site: {this.state.SiteName}</p>
                                <p>Requested Stock: {this.state.NewStock}</p>
                                <p>Notes: {this.state.Notes}</p>
                                
                            </ModalBody>
                            <ModalFooter>
                                <form onSubmit={this.approveOrderUdate}>
                                    <button type="submit" className="btn btn-primary mr-1">Complete Request</button>
                                    <button type="button" className="btn btn-secondary" onClick={this.handleCloseOrder}>Close</button>
                                </form>
                            </ModalFooter>
                        </Modal>

                    </div>
                </div>

            </div>
        );
    }
}

export default Dashboard;
