import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import './App.css';
import NavBar from './Components/NavBar/index.js';
import CustomerDashboard from './Pages/CustomerDashboardNew'
import CustomerOrders from './Pages/CustomerDashboardOrders'
import ViewCustomer from './Pages/ViewCustomer'
import ListCustomers from './Pages/ListCustomers'
import EditCustomer from './Pages/EditCustomer'
import Dashboard from './Pages/Dashboard'
import Login from './Pages/Login'
import AdminRoute from './Routes/AdminRoute'
import CustomerRoute from './Routes/CustomerRoute'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ApiURL: 'https://portal.enlabels.com:3000',
      //ApiURL: 'http://localhost:5001',
      LoggedInUser: {
        ID: '',
        Token: '',
        groupID: 0,
        customerID: 0
      },
      authed: false,
      username: '',
      password: '',
      wasInitialized: false,
      isLoading: true
    };
  }


  componentDidMount() {
    if (localStorage.getItem('Token')) {
      fetch(`${this.state.ApiURL}/api/checkauth`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('Token')
        }
      })
        .then(res => {
          return res.json()
        })
        .then(res => {
          if (res.success === true) {

            this.setUserState(res.userId, res.token, res.groupID, res.CustID, res.Username)
            localStorage.setItem('Token', res.token)

          } else {
            this.setState({
              authed: false,
              wasInitialized: true,
              isLoading: false
            })

          }
        })
        .catch(err => {
          console.log(err)
          this.setState({
            authed: false,
            wasInitialized: true,
            isLoading: false
          })
        })

    } else {

      this.setState({
        authed: false,
        wasInitialized: true,
        isLoading: false
      })
    }
  }

  setUserState = (userId, Token, groupID, custID, Username) => {
    this.setState({
      authed: true,
      LoggedInUser: {
        ID: userId,
        Token: Token,
        groupID: groupID,
        customerID: custID,
        Username: Username
      },
      wasInitialized: true,
      isLoading: false
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }


  render() {
    
    if (this.state.isLoading) {
      return <div>Loading</div>;
    } else {
      return (
        <div style={{ paddingBottom: "50px" }}>
  
          <nav><NavBar username={this.state.LoggedInUser.Username} /></nav>
  
          <main role="main" style={{ marginTop: '90px' }} className="col-md-12 col-lg-12">
            <BrowserRouter>
              <Switch>
                <Route path="/login" render={(props) => <Login {...props} ApiURL={this.state.ApiURL} setUserState={this.setUserState} handleChange={this.handleChange} username={this.state.username} password={this.state.password} />} />
                <AdminRoute exact path="/" authed={this.state.authed} groupID={this.state.LoggedInUser.groupID} wasInitialized={this.state.wasInitialized} component={(props) => <Dashboard {...props} ApiURL={this.state.ApiURL} />} />
                <AdminRoute exact path="/customers" authed={this.state.authed} groupID={this.state.LoggedInUser.groupID} wasInitialized={this.state.wasInitialized} component={(props) => <ListCustomers {...props} ApiURL={this.state.ApiURL} />} />
                <AdminRoute path="/customers/:customer_id/:user_id" authed={this.state.authed} groupID={this.state.LoggedInUser.groupID} wasInitialized={this.state.wasInitialized} component={(props) => <ViewCustomer {...props} ApiURL={this.state.ApiURL} />} />
                <AdminRoute path="/customer/edit/:customer_id" authed={this.state.authed} groupID={this.state.LoggedInUser.groupID} wasInitialized={this.state.wasInitialized} component={(props) => <EditCustomer {...props} ApiURL={this.state.ApiURL} />} />
                <CustomerRoute exact path="/dashc" authed={this.state.authed} groupID={this.state.LoggedInUser.groupID} wasInitialized={this.state.wasInitialized} CustID={this.state.LoggedInUser.customerID} component={(props) => <CustomerDashboard {...props} ApiURL={this.state.ApiURL} groupID={this.state.LoggedInUser.groupID} userID={this.state.LoggedInUser.ID} CustID={this.state.LoggedInUser.customerID} />} />
                <CustomerRoute exact path="/dashc/:userID" authed={this.state.authed} groupID={this.state.LoggedInUser.groupID} wasInitialized={this.state.wasInitialized} CustID={this.state.LoggedInUser.customerID} component={(props) => <CustomerDashboard {...props} ApiURL={this.state.ApiURL} groupID={this.state.LoggedInUser.groupID} userID={this.state.LoggedInUser.ID} CustID={this.state.LoggedInUser.customerID} />} />
                <CustomerRoute exact path="/dash/orders" authed={this.state.authed} groupID={this.state.LoggedInUser.groupID} wasInitialized={this.state.wasInitialized} CustID={this.state.LoggedInUser.customerID} component={(props) => <CustomerOrders {...props} ApiURL={this.state.ApiURL} groupID={this.state.LoggedInUser.groupID} userID={this.state.LoggedInUser.ID} CustID={this.state.LoggedInUser.customerID} />} />
                           
             </Switch>
            </BrowserRouter>
          </main>
  
        </div>
      );
    }
    
  }
}

export default App;
