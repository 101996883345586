import React, { Component } from 'react';
import './Login.css';
class Login extends Component {

  state = {
    statustext: ''
  }

  loginbtn = () => {
    fetch(`${this.props.ApiURL}/api/login`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.props.username,
        password: this.props.password,
      })
    })
      .then(res => {
        return res.json()
      })
      .then(res => {
        if (res.success === false) {
          this.setState({
            statustext: "Incorrect Username or Password"
          })
        } else if (res.success === true) {
          this.props.setUserState(res.userId, res.token, res.groupID, res.CustID, res.Username)
          localStorage.setItem('Token', res.token)
          this.props.history.push("/");

        }
      })
      .catch(console.log)
  }

  render() {
    return (
      <div className="container h-100">
        <div className="d-flex justify-content-center h-100">
          <div className="user_card">
            <div className="d-flex justify-content-center form_container">

              <form>
                  <h1 style={{color: '#fff', marginBottom: '20px'}}>Login</h1>
                <div className="statustext col-md-12">{this.state.statustext}</div>
                <div className="input-group mb-3">
                  <div className="input-group-append">
                    <span className="input-group-text"><i className="fa fa-user"></i></span>
                  </div>
                  <input value={this.props.username} onChange={this.props.handleChange} type="text" id="username" className="form-control input_user" placeholder="Email Address" />
                </div>
                <div className="input-group mb-2">
                  <div className="input-group-append">
                    <span className="input-group-text"><i className="fa fa-key"></i></span>
                  </div>
                  <input value={this.props.password} onChange={this.props.handleChange} type="password" id="password" className="form-control input_pass" placeholder="Password" />
                </div>
                <button type="button" onClick={this.loginbtn} name="button" className="btn login_btn">Login</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
