import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import MaterialTable, { MTableToolbar } from 'material-table'

const productIDs = []



class CustomerDashboard extends Component {

    state = {
        isOpenUpdate: false,
        isOpenRequest: false,
        isOpenLogo: false,
        customerName: '',
        productList: [],
        productTable: [],
        productList1: [],
        usersName: '',
        userExists: true,
        user_id: this.props.match.params.userID ? this.props.match.params.userID : this.props.userID ,
        users: [],
        isFlushed: false,
        CurrentStock: 0,
        NewStock: 0,
        popupID: 0,
        tempcustID: this.props.CustID,
        tempGroupID: 1,
        orderQty: 0,
        uploadPO: 0,
        notes: '',
        message: "",
        seePrices: 0,
        IMGdimensions: "",
        orders: [],
        file: ""

    }


    componentDidMount() {

        this.fetchProducts()

        fetch(`${this.props.ApiURL}/api/orders`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.ok) {
                    return res;
                } else {
                    throw Error(`Request rejected with status ${res.status}`);
                }
            })
            .then(res => {
                return res.json()
            })
            .then(body => {

                return body.Orders.map(order => {

                    return this.setState({ orders: [...this.state.orders, { id: order.id, Product: `<p>${order.ProductName}</p>`, Customer: `<p>${order.CustomerName}</p>`, UserName: `<p>${order.Name}</p>`, Qty: `<p>${order.QtyRequested}</p>`, Date: `<p>${this.ConvertDate(order.DateRequested)}</p>`, Actions: [order.id, order.ProductName, , order.QtyRequested, order.ProductID, order.UserID, order.CustomerID, order.CustomerName, order.Name, order.Notes] }] })

                })
            })
            .catch(console.error)

        fetch(`${this.props.ApiURL}/api/customers/${this.state.tempcustID}/users`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.ok) {
                    return res;
                } else {
                    throw Error(`Request rejected with status ${res.status}`);
                }
            })
            .then(res => {
                return res.json()
            })
            .then(body => {
                this.setState({
                    users: body.Customer
                })
            })
            .then(body => {

                console.log(this.state.users)

            })
            .catch(console.error)
    }


    fetchProducts = () => {
        
        fetch(`${this.props.ApiURL}/api/users/${this.state.user_id}/${this.state.tempcustID}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.ok) {
                    return res;
                } else {
                    throw Error(`Request rejected with status ${res.status}`);
                }
            })
            .then(res => {
                return res.json()
            })
            .then(body => {
                if (body.Customer.length === 0) {
                    this.setState({
                        customerName: "Customer Not Found",
                        usersName: "User Not Found",
                        userExists: false
                    })
                } else {
                    this.setState({
                        customerName: body.Customer[0].CustomerName,
                        usersName: body.Customer[0].Name,
                        userExists: true,
                        seePrices: body.Customer[0].Price
                    })
                }

            })
            .catch(console.log)

        fetch(`${this.props.ApiURL}/api/products/${this.state.tempcustID}/${this.state.user_id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.ok) {
                    return res;
                } else {
                    throw Error(`Request rejected with status ${res.status}`);
                }
            })
            .then(res => {
                return res.json()
            })
            .then(body => {
                this.setState({
                    productTable: body.Products
                })
            })
            .catch(console.log)

    }

    handleChangeUser = (userID) => {
        this.props.history.push(`/dashc/${userID}`)
        window.location.reload();
    }

    handleOpen = ({ popupID, mode }) => {

        if (mode === 1) {
            this.setState({
                popupID: popupID[0],
                PopupTitle: popupID[2],
                CurrentStock: popupID[1],
                isOpenRequest: true
            })
        } else {
            this.setState({
                popupID: popupID[0],
                PopupTitle: popupID[2],
                CurrentStock: popupID[1],
                isOpenUpdate: true
            })

        }

    }

    ConvertDate = (dateInput) => {

        return dateInput.slice(8, 10) + "-" + dateInput.slice(5, 7) + "-" + dateInput.slice(0, 4)

    }

    handleCloseUpdate = () => {

        this.setState({

            isOpenUpdate: false
        })

    }

    handleCloseRequest = () => {

        this.setState({
            isOpenRequest: false
        })

    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleShowOrders = (userID) => {
        this.props.history.push(`/dash/orders`)
        window.location.reload();
    }

    showUsers = () => {
        if (this.props.groupID === 19) {
            return <div className="topButtonLightBanner row">
                <div className="col-md-10">
                <button key="0" onClick={() => { this.handleShowOrders() }} type="button" className="btn btn-primary mr-2">Orders</button>
                    {this.state.users.map((user) => {
                        return <button key={user.id} onClick={() => { this.handleChangeUser(user.id) }} type="button" className="btn btn-primary mr-2">{user.Name}</button>

                    })}
                </div>
                <div className="col-md-2">

                </div>

            </div>

        } else {
            return null
        }

    }

    onFileChange = (e) => {
        this.setState({ file: e.target.files[0] })
    }

    requestStock = (e) => {
        e.preventDefault()
        const formData = new FormData();

        formData.append('Img', this.state.file)
        formData.append('ProductID', this.state.popupID)
        formData.append('CustomerID', this.state.tempcustID)
        formData.append('UserID', this.state.user_id)
        formData.append('QtyRequested', this.state.orderQty)
        formData.append('RequestedUserID', this.state.user_id)
        formData.append('Notes', this.state.notes)

        axios.post(`${this.props.ApiURL}/api/orders`, formData)
            .then(() => {
                this.setState({ isOpenRequest: false, message: "Order has been submitted" })
            })
            .catch((e) =>{
                console.log(e)
            })

    }

    
    updateStock = (e) => {
        e.preventDefault()
        fetch(`${this.props.ApiURL}/api/stock`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ProductID: this.state.popupID,
                CustomerID: this.state.tempcustID,
                UserID: this.state.user_id,
                Stock: this.state.NewStock
            })
        })
        .then(res => {
            fetch(`${this.props.ApiURL}/api/stockupdates`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductID: this.state.popupID,
                    CustomerID: this.state.tempcustID,
                    UserID: this.state.user_id,
                    NewStock: this.state.NewStock,
                    RequestedUserID: this.state.user_id,
                    CurrentStock: this.state.CurrentStock
    
                })
            })
            .catch(this.setState({ isOpenUpdate: false, message: "Something went wrong" }))
        })
            .then(res => {
                this.setState({ isOpenUpdate: false, message: "Stock Update has been submitted" }, this.fetchProducts)
            })
            .catch(this.setState({ isOpenUpdate: false, message: "Something went wrong" }))

    }

    handleOpenLogo = ({ url, id, prodID }) => {

        this.setState({
            isOpenLogo: true,
            popupURL: id,
            popupID: prodID
        })

    }

    handleCloseLogo = () => {

        this.setState({
            isOpenLogo: false
        })

    }

    onImgLoad = ({ target: img }) => {
        this.setState({
            IMGdimensions: {
                height: img.offsetHeight,
                width: img.offsetWidth
            }
        });
    }

    RenderColums = () => {

        if (this.state.seePrices === 0) {
            return [
                { title: 'id', field: 'id', width: "2px", hidden: true },
                { title: 'ID', field: 'EnLabelsID', width: "2px"},
                { title: 'Artwork', field: 'ArtworkURL', width: "10%", render: (rowData) => <img onClick={() => this.handleOpenLogo({ imageURL: rowData.ArtworkURL, id: this.props.ApiURL + rowData.ArtworkURL, prodID: rowData.id })} style={{ maxWidth: "160px", paddingRight: "20px" }} src={this.props.ApiURL + rowData.ArtworkURL}></img> },
                { title: 'Label Details', field: 'ProductName', width: "20%", render: (rowData) => <div><h3>{rowData.ProductName}</h3><p>{rowData.ProductDescription}</p></div> },
                { title: 'LeadTime', field: 'LeadTime', width: "10%", render: (rowData) => <div><p>{rowData.LeadTime}</p></div> },
                { title: 'Stock', field: 'Stock', width: "10%" },
                { title: 'Action', field: 'Stock', width: "10%", render: (rowData) => <span><button onClick={() => this.handleOpen({ popupID: [rowData.id, rowData.Stock, rowData.LabelDetails, rowData.ProductDescription, rowData.LeadTime, rowData.Price, rowData.EnLabelsID, rowData.Active], mode: 1 })} type="button" className="btn btn-primary mb-3"> Request More</button> <button onClick={() => this.handleOpen({ popupID: [rowData.id, rowData.Stock, rowData.LabelDetails, rowData.ProductDescription, rowData.LeadTime, rowData.Price, rowData.EnLabelsID, rowData.Active], mode: 2 })} type="button" className="btn btn-secondary mb-3"> Update Stock</button></span> }
            ];
        } else {
            return [
                { title: 'id', field: 'id', width: "2px", hidden: true },
                { title: 'ID', field: 'EnLabelsID', width: "2px"},
                { title: 'Artwork', field: 'ArtworkURL', width: "10%", render: (rowData) => <img onClick={() => this.handleOpenLogo({ imageURL: rowData.ArtworkURL, id: this.props.ApiURL + rowData.ArtworkURL, prodID: rowData.id })} style={{ maxWidth: "160px", paddingRight: "20px" }} src={this.props.ApiURL + rowData.ArtworkURL}></img> },
                { title: 'Label Details', field: 'ProductName', width: "20%", render: (rowData) => <div><h3>{rowData.ProductName}</h3><p>{rowData.ProductDescription}</p></div> },
                { title: 'Price', field: 'Price', width: "20%", render: (rowData) => <div><p>{rowData.Price}</p></div> },
                { title: 'LeadTime', field: 'LeadTime', width: "10%", render: (rowData) => <div><p>{rowData.LeadTime}</p></div> },
                { title: 'Stock', field: 'Stock', width: "10%" },
                { title: 'Action', field: 'Stock', width: "10%", render: (rowData) => <span><button onClick={() => this.handleOpen({ popupID: [rowData.id, rowData.Stock, rowData.LabelDetails, rowData.ProductDescription, rowData.LeadTime, rowData.Price, rowData.EnLabelsID, rowData.Active], mode: 1 })} type="button" className="btn btn-primary mb-3"> Request More</button> <button onClick={() => this.handleOpen({ popupID: [rowData.id, rowData.Stock, rowData.LabelDetails, rowData.ProductDescription, rowData.LeadTime, rowData.Price, rowData.EnLabelsID, rowData.Active], mode: 2 })} type="button" className="btn btn-secondary mb-3"> Update Stock</button></span> }
            ];
        }
    }


    render() {
        return (

            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="topLightBanner">
                            <h1>{this.state.customerName}</h1>
                            <h3 style={{ color: "#676A79" }}>{this.state.usersName}</h3>
                        </div>
                        {this.showUsers()}

                    </div>
                </div>

                <div className="row" style={{ marginLeft: '70px', marginRight: '70px' }}>
                    <div className="col-md-12" >
                        <div style={{ margin: "auto", width: "100%", textAlign: "center", color: "red" }}>{this.state.message}</div>
                        <MaterialTable
                            columns={this.RenderColums()}
                            data={this.state.productTable}
                            title=""
                            options={{
                                headerStyle: {
                                    backgroundColor: 'white',
                                    borderBottomColor: "Black",
                                    fontSize: "18px",
                                    fontFamily: "Poppins"
                                },
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 50, 100, 500],
                                search: true,
                                actionsColumnIndex: -1,
                                paging: false,
                                searchFieldAlignment: "left"
                            }}
                            components={{
                            }}
                        />
                    </div>
                </div>

                <Modal isOpen={this.state.isOpenUpdate} centered={true}>
                    <ModalHeader >Update Stock</ModalHeader>
                    <ModalBody>
                        <h3>{this.state.PopupTitle}</h3>
                        <p>Current Stock: {this.state.CurrentStock}</p>
                        <div className="row mt-4">
                            <div className="col-4 newStock">New Stock</div>
                            <div className="col-8"><input type="text" onChange={this.handleChange} value={this.state.NewStock} name="NewStock" id="NewStock" class="form-control" placeholder="" /></div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <form onSubmit={this.updateStock}>
                            <button type="submit" className="btn btn-primary mr-1">Update</button>
                            <button type="button" className="btn btn-secondary" onClick={this.handleCloseUpdate}>Close</button>
                        </form>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.isOpenRequest} centered={true}>
                    <ModalHeader >Request More</ModalHeader>
                    <ModalBody>
                        <h3>{this.state.PopupTitle}</h3>
                        <p>Current Stock: {this.state.CurrentStock}</p>
                        <div className="row mt-4">
                            <div className="col-4 newStock">Order Qty</div>
                            <div className="col-8"><input type="text" onChange={this.handleChange} value={this.state.orderQty} name="orderQty" id="orderQty" className="form-control" placeholder="" /></div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-4 newStock">Upload PO</div>
                            <div className="col-8"><input type="file" onChange={this.onFileChange} name="uploadPO" id="uploadPO" className="form-control" placeholder="" /></div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-4 newStock">Notes</div>
                            <div className="col-8"><input type="text" onChange={this.handleChange} value={this.state.notes} name="notes" id="notes" className="form-control" placeholder="" /></div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <form onSubmit={this.requestStock}>
                            <button type="submit" className="btn btn-primary mr-1">Request</button>
                            <button type="button" className="btn btn-secondary" onClick={this.handleCloseRequest}>Close</button>
                        </form>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.isOpenLogo} centered={true} className={(this.state.IMGdimensions.width / this.state.IMGdimensions.height) > 1.25 ? 'ImageLong' : 'ImageSquare'}>
                    <ModalHeader >View Artwork</ModalHeader>
                    <ModalBody>
                        <img style={{ maxWidth: "90%", margin: "40px auto", display: "block" }} onLoad={this.onImgLoad} src={this.state.popupURL}></img>
                    </ModalBody>
                    <ModalFooter>
                        <div className="form-group col-md-12 row">
                            <button type="button" className="btn btn-secondary ml-2" onClick={this.handleCloseLogo}>Close</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default withRouter(CustomerDashboard);
