import React, { Component } from 'react';
import '../Styles/ListCustomers.css';
import CustomerCard from '../Components/ListCustomers/CustomerCard'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';



class ListCustomers extends Component {

    state = {
        CustomerList: [], 
        isOpen: false,
        file: '',
        name: ''
    }

    componentDidMount() {
        fetch(`${this.props.ApiURL}/api/customers`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.ok) {
                    return res;
                } else {
                    throw Error(`Request rejected with status ${res.status}`);
                }
            })
            .then(res => {
                return res.json()
            })
            .then(body => {
                this.setState({
                    CustomerList: body.Customers
                })
            })
            .catch(console.error)

    }

    handleChange = (e) => {
        this.setState({
          [e.target.id]: e.target.value
        })
      }

      onFileChange = (e) => {
        this.setState({ file: e.target.files[0] })
    }

    addCustomer = () =>{
        const formData = new FormData();

        formData.append('Img', this.state.file)
        formData.append('customerName', this.state.name)

        axios.post(`${this.props.ApiURL}/api/customers`, formData)
        .then((body) => {
            this.handleClose()
        })
        .then(() =>{
            fetch(`${this.props.ApiURL}/api/customers`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {
                    if (res.ok) {
                        return res;
                    } else {
                        throw Error(`Request rejected with status ${res.status}`);
                    }
                })
                .then(res => {
                    return res.json()
                })
                .then(body => {
                    this.setState({
                        CustomerList: body.Customers
                    })
                })
                .catch(console.error)
        })


    }

    handleOpen = () => {

        this.setState({
            isOpen: true
        })

    }

    handleClose = () => {

        this.setState({

            isOpen: false
        })

    }

    render() {


        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="topLightBanner">
                            <h1>View Customers</h1>

                        </div>
                        <div className="topButtonLightBanner">
                            <button type="button" onClick={this.handleOpen} className="btn btn-primary mr-2">Add Customer</button>
                        </div>
                        <div className="row ml-5 mr-5 mt-5">
                            {this.state.CustomerList.map(customer => {
                                return <CustomerCard key={customer.id} ApiURL={this.props.ApiURL} customerId={customer.id} customerName={customer.CustomerName} customerLogo={customer.LogoURL} />
                            })}
                        </div>

                        <Modal isOpen={this.state.isOpen} centered={true}>
                            <ModalHeader >Add New Customer</ModalHeader>
                            <ModalBody>

                                <div className="row mt-2">
                                    <div className="col-4 newStock">Name</div>
                                    <div className="col-8"><input type="text" onChange={this.handleChange} value={this.state.name} id="name" className="form-control" placeholder="" /></div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-4 newStock">Logo</div>
                                    <div className="col-8"><input type="file" id="file" onChange={this.onFileChange} className="form-control"/></div>
                                </div>
 
                            </ModalBody>
                            <ModalFooter>
                                    <div className="form-group col-md-12 row">
                                        <button type="submit" onClick={this.addCustomer} className="btn btn-secondary">Add Customer</button>
                                        <button type="button" className="btn btn-secondary ml-2" onClick={this.handleClose}>Close</button>
                                    </div>
                            </ModalFooter>
                        </Modal>

                    </div>
                </div>

            </div>
        );
    }
}

export default ListCustomers;
