import React from "react";
import { Route, Redirect } from "react-router-dom";

const AdminRoute = ({
  component: Component,
  authed,
  groupID,
  wasInitialized,
  ...rest
}) => {
  return (
              <Route
          {...rest}
          render={(props) => {
            if (authed === true & groupID === 38) {
              return <Component {...props} />
            } else if (authed === true & groupID === 15) {
              return <Redirect to={{ pathname: '/dashc', state: { from: props.location } }} />
            } else if (authed === true & groupID === 19) {
              return <Redirect to={{ pathname: '/dashc', state: { from: props.location } }} />
            }else if (!wasInitialized) {
              return ""
            } else {
              return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }
          }
        }
        />
  );
};

export default AdminRoute;