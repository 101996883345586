import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import MaterialTable, { MTableToolbar } from 'material-table'

const productIDs = []


class CustomerDashboard extends Component {

    state = {
        customerName: '',
        usersName: '',
        users: [],
        isFlushed: false,
        tempcustID: this.props.CustID,
        tempGroupID: 1,
        orders: [],
        isOpenRequest: false,
        orderQty: 0,
        ApproveID:0

    }


    componentDidMount() {
        this.loadOrders()

        fetch(`${this.props.ApiURL}/api/customers/${this.state.tempcustID}/users`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.ok) {
                    return res;
                } else {
                    throw Error(`Request rejected with status ${res.status}`);
                }
            })
            .then(res => {
                return res.json()
            })
            .then(body => {
                this.setState({
                    users: body.Customer
                })
            })
            .then(body => {

                console.log(this.state.users)

            })
            .catch(console.error)
    }

    loadOrders = () => {
        fetch(`${this.props.ApiURL}/api/custorders/${this.state.tempcustID}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.ok) {
                    return res;
                } else {
                    throw Error(`Request rejected with status ${res.status}`);
                }
            })
            .then(res => {
                return res.json()
            })
            .then(body => {

                this.setState({
                    orders: body.Orders
                })
                
            })
            .catch(console.error)
    }

    handleChangeUser = (userID) => {
        this.props.history.push(`/dashc/${userID}`)
        window.location.reload();
    }

    handleShowOrders = (userID) => {
        this.props.history.push(`/dash/orders`)
        window.location.reload();
    }

    ConvertDate = (dateInput) => {
        return dateInput.slice(8, 10) + "-" + dateInput.slice(5, 7) + "-" + dateInput.slice(0, 4);

    }

    showUsers = () => {
        if (this.props.groupID === 19) {
            return <div className="topButtonLightBanner row">
                <div className="col-md-10">
                    <button key="0" onClick={() => { this.handleShowOrders() }} type="button" className="btn btn-secondary mr-2">Orders</button>
                    {this.state.users.map((user) => {
                        return <button key={user.id} onClick={() => { this.handleChangeUser(user.id) }} type="button" className="btn btn-primary mr-2">{user.Name}</button>

                    })}
                </div>
                <div className="col-md-2">

                </div>

            </div>

        } else {
            return null
        }

    }

    approveOrderUdate = (e) => {
        e.preventDefault()
        fetch(`${this.props.ApiURL}/api/order/customerapprove`, {
            method: "PUT",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({

                Orderid: this.state.ApproveID,
                qtyToOrder: this.state.orderQty

            })
          })
            .then(res => {
                this.setState({
                    message: "Order Approved",
                    isOpenRequest: false
                })
                this.loadOrders()
            })
    }

    approveOrderDecline = (e) => {
        fetch(`${this.props.ApiURL}/api/order/customerdecline`, {
            method: "PUT",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({

                Orderid: e

            })
          })
            .then(res => {
                this.setState({
                    message: "Order Declined"
                })
                this.loadOrders()
            })
    }

    openApprove = (id, qty) => {
        
        this.setState({
            isOpenRequest: true,
            orderQty: qty,
            ApproveID: id
        })
    }

    closeApprove = (id, qty) => {
        
        this.setState({
            isOpenRequest: false
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }


    render() {
        return (

            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="topLightBanner">
                            <h1>{this.state.customerName}</h1>
                            <h3 style={{ color: "#676A79" }}>{this.state.usersName}</h3>
                        </div>
                        {this.showUsers()}

                    </div>
                </div>

                <div className="row" style={{ marginLeft: '70px', marginRight: '70px' }}>
                    <div className="col-md-12 orders" >
                        <div style={{ margin: "auto", width: "100%", textAlign: "center", color: "red" }}>{this.state.message}</div>
                        <MaterialTable
                            columns={[{ title: 'Product', field: 'ProductName', width: "2px" },
                            { title: 'Site Name', field: 'Name', width: "2px" },
                            { title: 'Current Stock', field: 'Stock', width: "2px" },
                            { title: 'Qty Requested', field: 'QtyRequested', width: "2px" },
                            { title: 'Date', field: 'DateRequested', width: "2px", render:(rowData) => {return this.ConvertDate(rowData.DateRequested)}},
                            { title: 'Actions', field: 'Actions', width: "2px", render: (rowData) => <span><button onClick={() => this.openApprove(rowData.id, rowData.QtyRequested)} type="button" className="btn btn-primary mb-3">Approve</button> <button onClick={() => this.approveOrderDecline(rowData.id)} type="button" className="btn btn-secondary mb-3">Decline</button></span> }]}
                            data={this.state.orders}
                            title=""
                            options={{
                                headerStyle: {
                                    backgroundColor: 'white',
                                    borderBottomColor: "Black",
                                    fontSize: "18px",
                                    fontFamily: "Poppins"
                                },
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 50, 100, 500],
                                search: false,
                                actionsColumnIndex: -1,
                                paging: false,
                                searchFieldAlignment: "left"
                            }}
                            components={{
                            }}
                        />
                    </div>
                </div>

                <Modal isOpen={this.state.isOpenRequest} centered={true}>
                    <ModalHeader >Approve Order</ModalHeader>
                    <ModalBody>
                        <div className="row mt-4">
                            <div className="col-4 newStock">Order Qty</div>
                            <div className="col-8"><input type="text" onChange={this.handleChange} value={this.state.orderQty} name="orderQty" id="orderQty" className="form-control" placeholder="" /></div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <form onSubmit={this.approveOrderUdate}>
                            <button type="submit" className="btn btn-primary mr-1">Approve & Send</button>
                            <button type="button" className="btn btn-secondary" onClick={this.closeApprove}>Close</button>
                        </form>
                    </ModalFooter>
                </Modal>            

            </div>
        );
    }
}

export default withRouter(CustomerDashboard);
