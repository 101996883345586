import React from 'react';
import enlabelsLogo from '../../Images/enLabels.jpg';

    const logout = () => {

        localStorage.removeItem('Token')
        window.location.reload();
    }

    const NavBar = (props) => {

        return (

            <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0">
                <a className="navbar-brand col-sm-6 col-md-6 mr-0" href="/"><img src={enlabelsLogo} className="ml-3 brand_logo" alt="Logo"/></a>
                <span className="NavUsername">{props.username}</span>
                <button type="button" onClick={logout} className="btn btn-primary ml-2 logout">Logout</button>
            </nav>



        )
    }

export default NavBar;
