import React, { Component } from 'react';
import ReactCollapsingTable from 'react-collapsing-table'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from "react-router-dom";
import EditUser from '../Components/TableFunctions/EditCustomer';
import axios from 'axios';

const group = [
    {
        "id": 15,
        "group": "Sub Account"
    },
    {
        "id": 19,
        "group": "Main Account"
    }
]

const prices = [
    {
        "id": 0,
        "price": "No"
    },
    {
        "id": 1,
        "price": "Yes"
    }
]

class ViewCustomer extends Component {

    columns = [
        { accessor: 'ID', label: 'ID', priorityLevel: 9, position: 1, minWidth: 100, },
        { accessor: 'Name', label: 'Name', priorityLevel: 3, position: 2, minWidth: 100, },
        { accessor: 'Username', label: 'Email Address', priorityLevel: 1, position: 3, minWidth: 310, },
        { accessor: 'Group', label: 'Group', priorityLevel: 5, position: 4, minWidth: 100, },
        { accessor: 'Price', label: 'Price', priorityLevel: 6, position: 5, minWidth: 100, },
        { accessor: 'Actions', label: 'Actions', priorityLevel: 2, position: 6, minWidth: 110, CustomComponent: EditUser }
    ]

    state = {
        isOpen: false,
        isOpenAddNew: false,
        isOpenLogo: false,
        customerName: '',
        customerNameTB: '',
        users: [],
        fullName: '',
        email: '',
        seePrices: 0,
        Group: '15',
        password: '',
        Group_List: group,
        Prices_List: prices,
        file: '',
        Logo: ""
    }


    componentDidMount() {



        fetch(`${this.props.ApiURL}/api/customers/${this.props.match.params.customer_id}/users`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.ok) {
                    return res;
                } else {
                    throw Error(`Request rejected with status ${res.status}`);
                }
            })
            .then(res => {
                return res.json()
            })
            .then(body => {

                return body.Customer.map(user => {

                    return this.setState({ users: [...this.state.users, { id: user.id, ID: `<p>${user.id}</p>`, Name: `<p>${user.Name}</p>`, Username: `<p>${user.Username}</p>`, Group: `<p>${user.GroupName}</p>`, Price: `<p>${user.seePrices}</p>`, Actions: [user.id, user.Name, user.Username, user.Price, user.GroupID] }] })

                })
            })
            .catch(console.error)


        fetch(`${this.props.ApiURL}/api/customers/${this.props.match.params.customer_id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.ok) {
                    return res;
                } else {
                    throw Error(`Request rejected with status ${res.status}`);
                }
            })
            .then(res => {
                return res.json()
            })
            .then(body => {
                this.setState({
                    customerName: body.Customer[0].CustomerName,
                    customerNameTB: body.Customer[0].CustomerName,
                    Logo: body.Customer[0].LogoURL
                })
            })
            .catch(console.error)

    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    updateCustomer = () => {
        fetch(`${this.props.ApiURL}/api/customer`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: this.props.match.params.customer_id,
                CustomerName: this.state.customerNameTB
            })
        })
            .then(res => {
                return res.json()

            })
            .then((body) => {
                return this.setState({
                    customerName: this.state.customerNameTB
                })
            })
            .catch(console.log)
    }

    updateLogo = () => {
        const formData = new FormData();

        formData.append('Img', this.state.file)
        formData.append('customerID', this.props.match.params.customer_id)

        axios.put(`${this.props.ApiURL}/api/customer/logo`, formData)
            .then((body) => {
console.log(body.data.URL)
                this.setState({
                    Logo: body.data.URL
                })
            })
            .then((body) => {
                this.handleCloseLogo()
            })
            .catch(console.log)
    }

    updateUser = () => {
        fetch(`${this.props.ApiURL}/api/users/${this.state.popupID}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Username: this.state.email,
                Name: this.state.fullName,
                GroupID: this.state.Group,
                Price: this.state.seePrices
            })
        })
            .then(res => {
                return res.json()

            })
            .then((body) => {
                return this.setState({
                    isOpen: false,
                    users: []
                })
            })
            .then(() => {
                fetch(`${this.props.ApiURL}/api/customers/${this.props.match.params.customer_id}/users`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        if (res.ok) {
                            return res;
                        } else {
                            throw Error(`Request rejected with status ${res.status}`);
                        }
                    })
                    .then(res => {
                        return res.json()
                    })
                    .then(body => {

                        return body.Customer.map(user => {

                            return this.setState({ users: [...this.state.users, { id: user.id, ID: `<p>${user.id}</p>`, Name: `<p>${user.Name}</p>`, Username: `<p>${user.Username}</p>`, Group: `<p>${user.GroupName}</p>`, Price: `<p>${user.seePrices}</p>`, Actions: [user.id, user.Name, user.Username, user.Price, user.GroupID] }] })

                        })
                    })
                    .catch(console.error)
            })
            .catch(console.log)
    }

    addUser = () => {
        console.log(this.state.Group)

        fetch(`${this.props.ApiURL}/api/users`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                CustomerID: this.props.match.params.customer_id,
                Username: this.state.email,
                Name: this.state.fullName,
                GroupID: this.state.Group,
                Password: this.state.password,
                Price: this.state.seePrices

            })
        })
            .then(res => {
                this.setState({
                    isOpenAddNew: false,
                    users: []
                })
            })
            .then(() => {
                fetch(`${this.props.ApiURL}/api/customers/${this.props.match.params.customer_id}/users`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        if (res.ok) {
                            return res;
                        } else {
                            throw Error(`Request rejected with status ${res.status}`);
                        }
                    })
                    .then(res => {
                        return res.json()
                    })
                    .then(body => {

                        return body.Customer.map(user => {

                            return this.setState({ users: [...this.state.users, { id: user.id, ID: `<p>${user.id}</p>`, Name: `<p>${user.Name}</p>`, Username: `<p>${user.Username}</p>`, Group: `<p>${user.GroupID}</p>`, Price: `<p>${user.Price}</p>`, Actions: [user.id, user.Name, user.Username, user.Price, user.GroupID] }] })

                        })
                    })
                    .catch(console.error)
            })
    }


    handleOpen = ({ popupID }) => {

        this.setState({
            popupID: popupID[0],
            fullName: popupID[1],
            email: popupID[2],
            seePrices: popupID[3],
            Group: popupID[4],
            isOpen: true
        })

    }

    handleOpenAddNew = () => {

        this.setState({
            Name: '',
            email: '',
            Group: '15',
            Password: '',
            Price: '',
            isOpenAddNew: true
        })

    }

    handleClose = () => {

        this.setState({

            isOpen: false
        })

    }

    handleCloseLogo = () => {

        this.setState({

            isOpenLogo: false
        })

    }

    handleOpenLogo = () => {

        this.setState({

            isOpenLogo: true
        })

    }

    handleCloseAddNew = () => {

        this.setState({

            isOpenAddNew: false
        })

    }

    handleChangeGroup = (e) => {
        this.setState({
            Group: e.target.value
        })
    }

    handleChangePrices = (e) => {
        this.setState({
            seePrices: e.target.value
        })
    }

    onFileChange = (e) => {
        this.setState({ file: e.target.files[0] })
    }

    render() {
        const callbacks = { Actions: this.handleOpen }
        return (

            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="topLightBanner">
                            <h1>Edit {this.state.customerName}</h1>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ marginLeft: '70px', marginRight: '70px' }}>
                    <form method="get" className="col-md-12 mt-5 mb-5" style={{ margin: 'auto' }}>
                        <div className="form-row ">
                            <div className="form-group col-md-6 row">
                                <label className="col-sm-3 col-form-label">Company Name: </label>
                                <div className="col-sm-8">
                                    <input tabIndex="1" type="text" value={this.state.customerNameTB} onChange={this.handleChange} className="form-control" id="customerNameTB" />
                                </div>
                            </div>
                            <div className="form-group col-md-6 row justify-content-end">
                                <label className="col-sm-3 col-form-label">Logo:</label>
                                <div className="col-sm-8">
                                    <img className="cardImage" src={this.props.ApiURL + this.state.Logo} alt="Customer Logo" />
                                </div>
                            </div>

                        </div>
                        <div className="form-row ">
                            <div className="form-group col-md-12 row">
                                <button type="submit" onClick={this.updateCustomer} className="btn btn-secondary mr-1 buttonRounded">Update Customer</button>
                                <button type="button" onClick={this.handleOpenLogo} className="btn btn-secondary mr-1 buttonRounded">Update Logo</button>
                                <button type="button" onClick={this.handleOpenAddNew} className="btn btn-secondary mr-1 buttonRounded">Add User </button>
                            </div>
                        </div>
                    </form>

                    <h2 className="mb-5" >Edit Users</h2>

                    <div className="col-md-12" >
                        <ReactCollapsingTable columns={this.columns}
                            rows={this.state.users}
                            rowSize={20}
                            showSearch={false}
                            callbacks={callbacks}
                            showPagination={true} />
                    </div>
                </div>

                <Modal isOpen={this.state.isOpen} centered={true}>
                    <ModalHeader >Edit User</ModalHeader>
                    <ModalBody>

                        <div className="row mt-2">
                            <div className="col-4 newStock">Full Name</div>
                            <div className="col-8"><input type="text" name="" onChange={this.handleChange} value={this.state.fullName} id="fullName" className="form-control" placeholder="" /></div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 newStock">Email</div>
                            <div className="col-8"><input type="text" name="" id="email" onChange={this.handleChange} value={this.state.email} className="form-control" placeholder="" /></div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 newStock">See Prices</div>
                            <div className="col-8">
                                <select className="form-control" value={this.state.seePrices} onChange={this.handleChangePrices} id="seePrices">
                                    {this.state.Prices_List.map(price => {
                                        return <option key={price.id} value={price.id}>{price.price}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 newStock">Group</div>
                            <div className="col-8">
                                <select className="form-control" value={this.state.Group} onChange={this.handleChangeGroup} id="Group">
                                    {this.state.Group_List.map(group => {
                                        return <option key={group.id} value={group.id}>{group.group}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="row mt-3">

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="form-group col-md-12 row">
                            <button type="submit" onClick={this.updateUser} className="btn btn-secondary ml-4">Update User</button>
                            <button type="button" className="btn btn-secondary ml-2" onClick={this.handleClose}>Close</button>
                        </div>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.isOpenAddNew} centered={true}>
                    <ModalHeader >Add User</ModalHeader>
                    <ModalBody>

                        <div className="row mt-2">
                            <div className="col-4 newStock">Full Name</div>
                            <div className="col-8"><input type="text" name="" onChange={this.handleChange} value={this.state.fullName} id="fullName" className="form-control" placeholder="" /></div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 newStock">Email</div>
                            <div className="col-8"><input type="text" name="" id="email" onChange={this.handleChange} value={this.state.email} className="form-control" placeholder="" /></div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 newStock">Password</div>
                            <div className="col-8"><input type="password" name="" id="password" onChange={this.handleChange} value={this.state.password} className="form-control" placeholder="" /></div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 newStock">See Prices</div>
                            <div className="col-8">
                                <select className="form-control" value={this.state.seePrices} onChange={this.handleChangePrices} id="seePrices">
                                    {this.state.Prices_List.map(price => {
                                        return <option key={price.id} value={price.id}>{price.price}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 newStock">Group</div>
                            <div className="col-8">
                                <select className="form-control" value={this.state.Group} onChange={this.handleChangeGroup} id="Group">
                                    {this.state.Group_List.map(group => {
                                        return <option key={group.id} value={group.id}>{group.group}</option>
                                    })}
                                </select>
                            </div>
                        </div>



                    </ModalBody>
                    <ModalFooter>
                        <div className="form-group col-md-12 row">
                            <button type="submit" onClick={this.addUser} className="btn btn-secondary ml-4">Add User</button>
                            <button type="button" className="btn btn-secondary ml-2" onClick={this.handleCloseAddNew}>Close</button>
                        </div>
                    </ModalFooter>
                </Modal>


                <Modal isOpen={this.state.isOpenLogo} centered={true}>
                    <ModalHeader >Update Logo</ModalHeader>
                    <ModalBody>
                        <div className="row mt-2">
                            <div className="col-4 newStock">Logo</div>
                            <div className="col-8"><input type="file" id="file" onChange={this.onFileChange} className="form-control" /></div>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <div className="form-group col-md-12 row">
                            <button type="submit" onClick={this.updateLogo} className="btn btn-secondary">Update Logo</button>
                            <button type="button" className="btn btn-secondary ml-2" onClick={this.handleCloseLogo}>Close</button>
                        </div>
                    </ModalFooter>
                </Modal>

            </div>
        );
    }
}

export default withRouter(ViewCustomer);
